import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Inicio from './inicio';
import Bio from './bio';
import Experiencia from './experiencia';
import Curriculum from './curriculum';
import Contenido from './contenido';
import Iot from './iot.js'
import Biomedica from './biomedica.js';
import Programacion from './programacion.js';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Inicio/>}/>
          <Route path="/bio" element={<Bio/>}/>
          <Route path="/experiencia" element={<Experiencia/>}/>
          <Route path="/contenido" element={<Contenido/>}/>
          <Route path="/iot" element={<Iot/>}/>
          <Route path="/biomedica" element={<Biomedica/>}/>
          <Route path="/programacion" element={<Programacion/>}/>
          <Route path="/curriculum" element={<Curriculum/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
